
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutPJhXuX58xSMeta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/about.vue?macro=true";
import { default as cabinetgHb2ST6sVjMeta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/account/cabinet.vue?macro=true";
import { default as _91id_93qrigRURYFNMeta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/actions/[id].vue?macro=true";
import { default as listNQILRo3pzrMeta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/actions/list.vue?macro=true";
import { default as _91id_93XsIbDfLBa2Meta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/articles/[id].vue?macro=true";
import { default as lista5PmTi3PcGMeta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/articles/list.vue?macro=true";
import { default as finalStep9Ho1uPRqAOMeta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/cart/finalStep.vue?macro=true";
import { default as firstStep3H3hIvjCeHMeta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/cart/firstStep.vue?macro=true";
import { default as secondStep8TsiSZrpa2Meta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/cart/secondStep.vue?macro=true";
import { default as contactsO5LOK6Z9e8Meta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/contacts.vue?macro=true";
import { default as customITvaAsFgGzMeta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/custom.vue?macro=true";
import { default as deliveryghQOfmQvGlMeta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/delivery.vue?macro=true";
import { default as feedbackd2rTRdcmFyMeta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/feedback.vue?macro=true";
import { default as indextEWjiQwRSoMeta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/index.vue?macro=true";
import { default as legal2I1sBEXXunMeta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/legal.vue?macro=true";
import { default as _91id_93bmf5zvHpucMeta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/menu/group/[id].vue?macro=true";
import { default as _91id_93yNuF12IZZzMeta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/menu/product/[id].vue?macro=true";
import { default as partnershipkIza1cgmtSMeta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/partnership.vue?macro=true";
import { default as _91id_93oENkUbrwfeMeta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/restaurants/[id].vue?macro=true";
import { default as listxq9WTG4UnPMeta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/restaurants/list.vue?macro=true";
import { default as off8hiGwcR8vfMeta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/system/off.vue?macro=true";
import { default as vacancyK2NH1LHFOaMeta } from "D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/vacancy.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/about.vue")
  },
  {
    name: "account-cabinet",
    path: "/cabinet",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/account/cabinet.vue")
  },
  {
    name: "actions-id",
    path: "/action/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/actions/[id].vue")
  },
  {
    name: "actions-list",
    path: "/actions",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/actions/list.vue")
  },
  {
    name: "articles-id",
    path: "/articles/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/articles/[id].vue")
  },
  {
    name: "articles-list",
    path: "/articles",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/articles/list.vue")
  },
  {
    name: "cart-finalStep",
    path: "/order/complete/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/cart/finalStep.vue")
  },
  {
    name: "cart-firstStep",
    path: "/order",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/cart/firstStep.vue")
  },
  {
    name: "cart-secondStep",
    path: "/order/delivery",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/cart/secondStep.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/contacts.vue")
  },
  {
    name: "custom",
    path: "/custom",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/custom.vue")
  },
  {
    name: "delivery",
    path: "/delivery",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/delivery.vue")
  },
  {
    name: "feedback",
    path: "/feedback",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/feedback.vue")
  },
  {
    name: "index",
    path: "/",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/index.vue")
  },
  {
    name: "legal",
    path: "/legal",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/legal.vue")
  },
  {
    name: "menu-group-id",
    path: "/menu/group/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/menu/group/[id].vue")
  },
  {
    name: "menu-product-id",
    path: "/menu/product/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/menu/product/[id].vue")
  },
  {
    name: "partnership",
    path: "/partners",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/partnership.vue")
  },
  {
    name: "restaurants-id",
    path: "/restaurants-details/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/restaurants/[id].vue")
  },
  {
    name: "restaurants-list",
    path: "/rest",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/restaurants/list.vue")
  },
  {
    name: "system-off",
    path: "/system/off",
    meta: {"HideFooter":true,"HideHeader":true},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/system/off.vue")
  },
  {
    name: "vacancy",
    path: "/vacancy",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/vacancy.vue")
  },
  {
    path: "/menu",
    name: "group|redirect-default-link",
    redirect: "/menu/00000000-0000-0000-0000-000000000000"
  },
  {
    name: "legal-pay-rules",
    path: "/rules",
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/legal.vue")
  },
  {
    name: "legal-personal-data",
    path: "/personaldata",
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/legal.vue")
  },
  {
    name: "legal-agreement",
    path: "/privacy",
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/legal.vue")
  },
  {
    name: "legal-points",
    path: "/points",
    component: () => import("D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-7de4774b-8f05-4f6a-84cb-b4046ba5bd4e/pages/legal.vue")
  }
]